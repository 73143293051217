import { useEffect, useState } from 'react';
import { get, post } from "superagent";

import { Calendar } from "./types";
import StudentView from "./student";
import InstructorView from "./instructor";

// Wrapper component that calls API and gets session to validate user role
// Returns student view if role is "student", and instructor view if role is "instructor"
const App = () => {

  const [role, setRole] = useState<string|null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<Calendar|null>(null);

  const validateUser = async () => {
    // Validate LTI
    await post(`${process.env.REACT_APP_API_URL}/validate-user/`)
    .withCredentials()
    .then((res: any) => {
      setRole(res.body.role);
      getCalendar();
      return true;
    })
    .catch((err: any) => {
      console.log(err);
      // Handle error
    });
    setIsReady(true);
  }

  const getCalendar = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/calendar`)
    .withCredentials()
    .then(((res: any) => {
      setCalendar(res.body)
    }), (err: any) => console.error(err.response));
    setIsReady(true);
  }

  useEffect(() => {
    validateUser();
  }, []);

  const hasRole = role === 'student' || role === 'instructor';

  if (isReady) {

    return (
      <div className="App">
        {hasRole && role === 'student' ? <StudentView calendar={calendar} /> : null}
        {hasRole && role === 'instructor' ? 
          <InstructorView calendar={calendar} handleGetCalendar={getCalendar} /> 
        : null}
        {!hasRole ? "Unauthorized" : null}
      </div>
    );
  }
  
  else return null;

}

export default App;
