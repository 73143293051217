
import { useState, useEffect } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { post, del, get } from "superagent";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import { Calendar, Item } from "../types";
import { colors, fileExtensions } from "../helpers";

interface DayViewProps {
  date: Date,
  handleSetDate: (d: any) => void,
  handleGetCalendar?: () => void,
  isInstructor: boolean,
  items: Array<Item>,
  calendar: Calendar
}

function Items(props: {
  items: Array<Item>, 
  handleSetShowNewItemModal: (show: boolean) => void;
  handleSetNewItem: (item: Item) => void;
  isInstructor: boolean;
}) {
  const { items, handleSetShowNewItemModal, handleSetNewItem, isInstructor } = props;

  return (
    <div className="items">
      <div className="accordion" id="item-accordion">
        {items.map(item => {
          const hasContent = item.startTime || item.description;
          // Return header only if there's only a name & times
          if (!isInstructor) {
            return (
              <div className="item">
                <div 
                  className={`item-header ${item.color} ${hasContent ? "top-radius" : "all-radius"}`} 
                  style={{backgroundColor: colors[item.color]}}>
                  {item.link ? 
                    <a className={`item-name ${item.color}`} href={item.link}>
                      {item.title} <i className="bi bi-link-45deg"/>
                    </a>
                    : <div className="item-name">{item.title}</div>
                    }
                </div>
                {hasContent ? 
                  <div className="item-body">
                    <div className="text-secondary">
                      {item.startTime ? `${item.startTime}`: ""}
                      {item.endTime ? ` - ${item.endTime}`: ""}
                    </div>
                    {item.description ? <p>{item.description}</p> : null}
                  </div>
                  : null 
                }
              </div>
            )
          }

          return (
            <div className="accordion-item" key={item.id}>
              <h2 className="accordion-header">
                <button 
                  className={`accordion-button ${item.color}`} 
                  style={{backgroundColor: colors[item.color]}}
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target={`#accordion-${item.id}`} 
                  aria-expanded="false" 
                  aria-controls={`accordion-${item.id}`}>
                  <div className="d-flex justify-content-between item-name-and-time">
                    <div className="item-name">
                      {item.title} {item.link ? <i className="bi bi-link-45deg"/> : null}
                    </div>
                    <span className="item-time">
                      {item.startTime ? `${item.startTime}`: ""}
                      {item.endTime ? ` - ${item.endTime}`: ""}
                    </span>
                  </div>
                </button>
              </h2>
              <div 
                id={`accordion-${item.id}`} 
                className="accordion-collapse collapse hide">
                <div className="accordion-body">
                  {item.link ? 
                    <a href={item.link} target="__blank">
                      {item.title} <i className="bi bi-box-arrow-up-right"/>
                    </a>
                    : null
                  }
                  <div className="d-flex justify-content-between align-items-end">
                    <p>{item.description}</p>

                    {isInstructor ? 
                      <button className="btn btn-primary btn-sm" onClick={() => {
                        handleSetNewItem({
                          ...item, 
                          isLink: item.link ? true : false,
                          hasTime: item.startTime ? true : false
                        });
                        handleSetShowNewItemModal(true);
                        }}>
                        <i className="bi bi-pencil"/>
                      </button>
                    : null}
                  </div>
                </div>
              </div>
            </div>
          )
        })}

      </div>
    </div>
  )
};

function NewItemForm(props: {
  item: Item,
  itemError: string,
  isEditing: boolean,
  createItem: (e: any) => void,
  setNewItem: (item: Item) => void,
  handleCloseModal: () => void,
  setShowSelectItemLink: (arg: boolean) => void,
  handleDeleteItem: (itemId: number) => void
}) {
  const { 
    item, 
    itemError,
    isEditing,
    createItem, 
    setNewItem, 
    handleCloseModal, 
    setShowSelectItemLink,
    handleDeleteItem
  } = props;

  return (
    <form className="form-control new-item-form" onSubmit={(e) => createItem(e)}>
      <Modal.Body>

        Text: <input 
          type="text"
          className="form-control"
          value={item.title}
          required
          onChange={(e: any) => setNewItem({...item, title: e.target.value})}/>
        <br/>

        Description: 
        <textarea
          value={item.description}
          className="form-control"
          onChange={(e: any) => setNewItem({...item, description: e.target.value})}/>
        <br/>

        Item is a link: <input 
          type="checkbox"
          checked={item.isLink}
          onChange={(e: any) => setNewItem({...item, isLink: e.target.checked})}
          />

        {item.isLink ? 
          <div>
            Enter Link or &nbsp;
            <button className="link" onClick={() => setShowSelectItemLink(true)}>
              choose an item from this course
            </button> 
            <input 
              type="text"
              className="form-control"
              value={item.link}
              required
              onChange={(e: any) => setNewItem({...item, link: e.target.value})}/>
          </div>
        : null}
        
        <br/><br/>

        Item Color:
        <div className="d-flex">
          <select 
            className="form-select" 
            aria-label="Default select example"
            onChange={(e) => setNewItem({...item, color: e.target.value})}>
            {Object.keys(colors).map(name => {
              return (
                <option 
                  key={name}
                  value={name} 
                  selected={name === "blue" ? true : false}>
                  {name}
                </option>
              )
            })}
          </select>
          <div className="color-preview" style={{backgroundColor: colors[item.color]}}/>
        </div>

        <br/>

        Include timeframe: <input 
          type="checkbox"
          checked={item.hasTime}
          onChange={(e: any) => setNewItem({...item, hasTime: e.target.checked})}
          />

        {item.hasTime ? 
          <div>
            Start Time: 
            <TimePicker 
              onChange={(e: any) => setNewItem({...item, startTime: e})} 
              value={item.startTime}
              format={"HH:mm"} 
              hourPlaceholder={"Hour"}
              minutePlaceholder={"Minutes"}
              disableClock={true}/>
            <br/>
            End Time:
            <TimePicker 
              onChange={(e: any) => setNewItem({...item, endTime: e})} 
              value={item.endTime}
              format={"HH:mm"} 
              hourPlaceholder={"Hour"}
              minutePlaceholder={"Minutes"}
              disableClock={true}/>
          </div>  
        : null}

      {itemError ? <div className="text-danger"><br/>{itemError}</div> : null}

      </Modal.Body>

      <Modal.Footer>
        {isEditing ? 
          <button 
            type="button"
            className="btn btn-danger" 
            onClick={() => item.id ? handleDeleteItem(item.id) : null}>
            Delete
          </button>
        : <div></div> }
        <div>
          <button className="btn btn-secondary" onClick={() => handleCloseModal()}>
            Cancel
          </button>
          <button className="btn btn-primary" type="submit">
            {isEditing ? "Save" : "Create"}
          </button>
        </div>
      </Modal.Footer>
    </form>
  )
};

function ResourceList(props: {
  resource: any,
  handleSelectLink: (link: string) => void
}) {
  const { resource, handleSelectLink } = props;
  const [resources, setResources] = useState<any>([]);
  const [error, setError] = useState<string>("");

  const getResources = async (url: string) => {
    await post(`${process.env.REACT_APP_API_URL}/api/sakai/resources`)
    .withCredentials()
    .send({path: url})
    .then(res => {
      setResources(res.body);
    })
    .catch(err => {
      console.log(err);
      if (err.response.text) {
        setError(err.response.text);
      } else {
        setError("There was an error connecting to Sakai.")
      }
    });
  };

  const expandOrMinimize = (e: React.MouseEvent<HTMLElement>) => {
    const iconEl = (e.target as Element)?.closest(".folder-name")?.querySelector(".bi");
    const subFolderEl = (e.target as Element)?.closest(".resource-list")?.querySelector(".sub-folder");
    if (subFolderEl && subFolderEl.classList.contains("expanded")) {
      subFolderEl.classList.remove("expanded");
      iconEl?.classList.remove("bi-caret-down");
      iconEl?.classList.add("bi-caret-right");
      
    } else if (subFolderEl) {
      subFolderEl.classList.add("expanded"); 
      iconEl?.classList.remove("bi-caret-right")
      iconEl?.classList.add("bi-caret-down")

    }
  }

  useEffect(() => {
    if (resource.type === "collection") {
      getResources(resource.url);
    }
  }, []);

  if (error) {
    return <p className="text-danger">error</p>;
  }

  if (resource.type === "resource") {
    const extension: string = resource.fileName.split(".").pop().toLowerCase();
    const iconClass = fileExtensions[extension] ? fileExtensions[extension] : "file-earmark";
    return (
      <div className="link-item">
        <span><i className={`bi bi-${iconClass}`}/> {resource.fileName}</span>
        <button className="btn btn-primary btn-sm" onClick={() => handleSelectLink(resource.url)}>
          Select
        </button>
      </div>
    );
  }

  return (
    <div className="resource-list">
      <div className="link-item">
        <div className="folder-name" onClick={(e: any) => expandOrMinimize(e)}>
          {resource.fileName}
          <i className="bi bi-caret-right"/>
        </div>

        <button 
          className="btn btn-primary btn-sm" 
          onClick={() => handleSelectLink(resource.url)}>
          Select Folder
        </button>
      </div>

      {resources.length > 0 ? 
        <ul className="sub-folder">
          {resources.map((r: any) => {
            return (
              <ResourceList resource={r} handleSelectLink={handleSelectLink}/>
            )
          })}
        </ul>
        : <ul className="sub-folder"><i>Folder empty</i></ul>
      }
    </div>

  )
  
}

function CourseLinkSelection(props: {
  setShowSelectItemLink: (arg: boolean) => void,
  setNewItem: (item: Item) => void,
  newItem: Item
}) {
  const initialCategories = {
    tools: [],
    assignments: [],
    resources: []
  }
  const { setShowSelectItemLink, setNewItem, newItem } = props;
  const [categories, setCategories] = useState<any>(initialCategories);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [sakaiError, setSakaiError] = useState<string>("");

  const getItemLinks = async () => {
    try {
      const toolsRes = await get(`${process.env.REACT_APP_API_URL}/api/sakai/tools`).withCredentials();
      const assignmentsRes = await get(`${process.env.REACT_APP_API_URL}/api/sakai/assignments`).withCredentials();
      const resourcesRes = await post(`${process.env.REACT_APP_API_URL}/api/sakai/resources`).withCredentials();

      setSakaiError("");
      setCategories({
        ...categories, 
        tools: toolsRes.body,
        assignments: assignmentsRes.body,
        resources: resourcesRes.body
      });
    
    }

    catch (err) {
      setSakaiError("There was an error connecting to Sakai.");
    }
    
    setIsReady(true);
  }

  const handleSelectLink = (link: string) => {
    setNewItem({...newItem, link});
    setShowSelectItemLink(false);
  };

  useEffect(() => {
    getItemLinks();
  }, [])

  if (isReady) {
    return (
      <Modal.Body>
        <button className="link" onClick={() => setShowSelectItemLink(false)}>
          <i className="bi bi-arrow-left"/> Back
        </button>
        <br/>
        <div className="accordion link-selection-accordion" id="item-accordion">
          {sakaiError ? 
            <div className="text-danger">{sakaiError}</div>
            : Object.keys(categories).map(catName => {
              return (
                <div className="accordion-item" key={catName}>
                  <h2 className="accordion-header" id="headingOne" >
                    <button 
                      className="accordion-button" 
                      type="button" 
                      data-bs-toggle="collapse" 
                      data-bs-target={`#accordion-${catName}`} 
                      aria-expanded="true" 
                      aria-controls={`accordion-${catName}`}>
                      {catName}
                    </button>
                  </h2>
                  <div 
                    id={`accordion-${catName}`} 
                    className="accordion-collapse collapse hide" 
                    aria-labelledby="headingOne" 
                    data-bs-parent="#item-accordion">
                    <div className="accordion-body">
                      <div className="link-list">
                        {categories[catName].map((item: any) => {
                          if (catName === "resources") {
                            return (
                              <ResourceList resource={item} handleSelectLink={handleSelectLink}/>
                            );
                          }
                          return (
                            <div className="link-item">
                              <span>{item.name}</span>
                              <button 
                                className="btn btn-primary btn-sm" 
                                onClick={() => handleSelectLink(item.url)}>
                                Select
                              </button>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }


        </div>
      </Modal.Body>
    )
  }
  else {
    return (
      <div className="loading-container">
        <span>Loading</span>
        <div className="spinner-border" role="status"/>
      </div>
    )
  }
}

function DayView(props: DayViewProps) {
  const { date, calendar, handleSetDate, handleGetCalendar, items, isInstructor } = props;

  const defaultItemVals = {
    title: "",
    description: "",
    link: "",
    date: date.toISOString(),
    startTime: null,
    endTime: null,
    color: "blue",
    isLink: false,
    hasTime: false
  }

  const [showNewItemModal, setShowNewItemModal] = useState<boolean>(false);
  const [showSelectItemLink, setShowSelectItemLink] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<Item>(defaultItemVals);
  const [itemError, setItemError] = useState<string>("");

  const createItem = async (e: any) => {
    if (isInstructor && handleGetCalendar) {
      e.preventDefault();
      await post(`${process.env.REACT_APP_API_URL}/api/item/${calendar.id}`)
      .withCredentials()
      .send({
        ...newItem,
        link: newItem.isLink ? newItem.link : null,
        startTime: newItem.hasTime ? newItem.startTime : null,
        endTime: newItem.hasTime ? newItem.endTime : null
      })
      .then(((res: any) => {
        setNewItem(defaultItemVals);
        setShowNewItemModal(false);
        handleGetCalendar();
        setItemError("");
      }), (err: any) => {
        if (err.response.text) {
          setItemError(err.response.text);
        }
        console.error(err);
      });
    }
  };

  const handleCloseModal = () => {
    setShowNewItemModal(false);
    setNewItem(defaultItemVals);
    setItemError("");
  }

  const deleteItem = async (itemId: number) => {
    if (isInstructor && handleGetCalendar) {
      await del(`${process.env.REACT_APP_API_URL}/api/item/${itemId}`)
      .withCredentials()
      .then(((res: any) => { 
        handleGetCalendar();
        handleCloseModal();
      }), (err: any) => console.error(err));
    }
  };

  const getFormattedDate = () => {
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    let formattedDay = day.toString();

    if (day >= 11 && day <= 13) {
      formattedDay += "th";
    }

    else {
      switch (day % 10) {
        case 1:
          formattedDay += "st"; break;
        case 2:
          formattedDay += "nd"; break;
        case 3:
          formattedDay += "rd"; break;
        default:
          formattedDay += "th"; break;
      }
    }

    return `${month} ${formattedDay}, ${year}`;

  }

  return (
    <div className="day-view">
      <div className="close-container">
        <i className="bi bi-x-lg" onClick={() => handleSetDate(null)}/>
      </div>
      <h2>{getFormattedDate()}</h2>
      <div>
        {items.length > 0 ? 
          <Items 
            items={items} 
            handleSetShowNewItemModal={setShowNewItemModal}
            handleSetNewItem={setNewItem}
            isInstructor={isInstructor}/>
          : <div>There are no items for this date.</div>
        }

        <br/>

        {isInstructor ? 
          <button className="btn btn-primary" onClick={() => setShowNewItemModal(true)}>
            Add Item +
          </button>
        : null }

      </div>

      <Modal show={showNewItemModal} onHide={() => handleCloseModal()}>
        <Modal.Header closeButton>
          <Modal.Title>{newItem.id ? "Update Item" : "Add Item"}</Modal.Title>
        </Modal.Header>

        { showSelectItemLink ? 
          <CourseLinkSelection 
            newItem={newItem} setNewItem={setNewItem} 
            setShowSelectItemLink={setShowSelectItemLink} /> 
          : <NewItemForm  
            item={newItem} createItem={createItem} setNewItem={setNewItem} itemError={itemError}
            handleCloseModal={handleCloseModal} isEditing={newItem.id ? true : false}
            setShowSelectItemLink={setShowSelectItemLink} handleDeleteItem={deleteItem}/>
        }

      </Modal>
    </div>
  );
}

export default DayView;