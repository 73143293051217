import React from 'react';
import { Calendar } from "../types"
import CalendarView from "../shared/calendar-view";

interface StudentViewProps {
  calendar: Calendar|null
}

function StudentView(props: StudentViewProps) {
  const { calendar } = props;

  if (calendar) {
    return (
      <div>
        <CalendarView calendar={calendar} isInstructor={false}/>
      </div>
    );
  }
  
  return <div>A calendar has not been selected. Please contact your instructor.</div>;

};

export default StudentView;
