import React from "react";
import moment from "moment";
import { useState, useEffect } from "react";
import { Calendar, Item } from "../types";
import DayView from "./day-view";
import { colors } from "../helpers";

interface CalendarViewProps {
  calendar: Calendar,
  isInstructor: boolean,
  handleGetCalendar?: () => void
}

const sortedWeekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

function Items(props: {items: Array<Item>}) {
  const { items } = props;

  return (
    <div className="calendar-item-container">
      {items.map((item: Item, i) => {

        if (i < 3) {
          return (
            <div className={`item ${item.color}`} style={{backgroundColor: colors[item.color]}}>
              {item.title} {item.link ? <i className="bi bi-link-45deg"/> : null}
            </div>
          )
        }
      })}
      {items.length > 3 ? 
        <div className="items-more">
          <i className="bi bi-plus-lg"/> {items.length - 3} more
        </div>
        : null
      }
    </div>
  )
};

function CalendarView(props: CalendarViewProps) {
  const { calendar, handleGetCalendar, isInstructor } = props;
  const [ displayDate, setDisplayDate ] = useState<Date>(new Date());
  const [ selectedDate, setSelectedDate ] = useState<Date|null>(null);

  const getFirstDisplayDate = () => {
    const startDate = new Date(calendar.startDate);
    const endDate = new Date(calendar.endDate);
    const currentDate = new Date();

    if (currentDate < startDate) {
      return startDate;
    }

    if (currentDate > endDate) {
      return endDate;
    }

    return currentDate;
  }

  const getPrevDate = () => {
    let prevMonth = displayDate.getMonth() - 1;
    let currentYear = displayDate.getFullYear();
    if (prevMonth === -1) {
      prevMonth = 11;
      currentYear--;
    }
    return new Date(currentYear, prevMonth);
  }

  const setPrevMonth = () => {
    const prevDate = getPrevDate();
    setDisplayDate(prevDate);
  }

  const setNextMonth = () => {
    const nextDate = getNextDate();
    setDisplayDate(nextDate);
  }

  const getNextDate = () => {
    let nextMonth = displayDate.getMonth() + 1;
    let currentYear = displayDate.getFullYear();
    if (nextMonth === 12) {
      nextMonth = 0;
      currentYear++;
    }
    return new Date(currentYear, nextMonth);
  }

  const getItemsByDate = (date: Date) => {
    return calendar.items.filter(i => i.date === date.toISOString());
  }

  const areSameDay = (date1: Date, date2: Date) => {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  };

  const getWeeksInMonth = (date: Date) => {
    const weeks: any = [];
    let week = [];

    if (calendar) {
      const firstDayInMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const month = firstDayInMonth.getMonth();
      const year = firstDayInMonth.getFullYear();
      const endDate = new Date(year, month + 1, 0);
      const currentDate = new Date(firstDayInMonth);
      const numDaysInWeek = Object.values(calendar.displayDays).filter(d => d ? true : false).length;

      while (currentDate.getMonth() === month) {
        
        const day = currentDate.toLocaleString('default', { weekday: 'long' }).toLowerCase();
        if (calendar.displayDays[day]) {
          week.push({date: new Date(currentDate), items: getItemsByDate(currentDate)});
        }

        if (currentDate.getDay() === 6 || currentDate.getTime() === endDate.getTime()) {
          // Pad first week with last days of last month
          if (weeks.length === 0 && week.length < numDaysInWeek) {
            let prevMonthLastDay = new Date(year, month, 0).getDate();
            let numPrevDays = 0;
            while (numDaysInWeek > week.length) {
              const date =  new Date(year, month - 1, prevMonthLastDay - numPrevDays);
              const day = date.toLocaleString('default', { weekday: 'long' }).toLowerCase();
              if (calendar.displayDays[day]) {
                week.unshift({date, items: getItemsByDate(currentDate)});
              }
              numPrevDays++;
            }
          }
          weeks.push(week);
          week = [];
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const lastWeek = weeks[weeks.length - 1];

      // Pad last week with first days of next month
      if (lastWeek.length < numDaysInWeek) {
        let firstDayNextMonth = new Date(year, month + 1, 1).getDate();
        let numNextDays = 0;
        while (numDaysInWeek > lastWeek.length) {
          const date =  new Date(year, month + 1, firstDayNextMonth + numNextDays);
          const day = date.toLocaleString('default', { weekday: 'long' }).toLowerCase();
          if (calendar.displayDays[day]) {
            lastWeek.push({date, items: getItemsByDate(currentDate)});
          }
          numNextDays++;
        }
      }

      return weeks;
    }
  };

  const monthInRange = (date: Date, endDate: Date) => {
    let year = date.getFullYear();
    let endYear = endDate.getFullYear();

    if (year < endYear) {
        return true;
    } else if (year > endYear) {
        return false;
    }

    // If the years are equal, compare the months
    let monthOfYear = date.getMonth();
    let monthOfEndYear = endDate.getMonth();

    return monthOfYear <= monthOfEndYear;
  }

  useEffect(() => {
    const firstDisplayDate: Date = getFirstDisplayDate();
    setDisplayDate(firstDisplayDate);
  }, []);

  const month = displayDate.toLocaleString('default', { month: 'long' });
  const prevDate = getPrevDate();
  const nextDate = getNextDate();
  const year = displayDate.getFullYear();
  const weeks = getWeeksInMonth(displayDate);
  const nextMonthIsBeforeEndDate = monthInRange(nextDate, new Date(calendar.endDate));
  const prevMonthIsBeforeStartDate = monthInRange(new Date(calendar.startDate), prevDate);

  return (
    <div className="calendar-container">
      <div className="calendar">
        <div className="prev-next-container">
          <div className="prev">
            {prevMonthIsBeforeStartDate ? 
              <div onClick={() => setPrevMonth()}><i className="bi bi-caret-left"/>Prev</div> 
            : <div></div>}
          </div>
          <span className="month-name"><b>{month}</b>{" " + year}</span>
          <div className="next">
            {nextMonthIsBeforeEndDate ? 
              <div onClick={() => setNextMonth()}>Next<i className="bi bi-caret-right"/></div> 
            : <div></div>}
          </div>
        </div>
        <div className="month">
          <div className="weekday">
            {sortedWeekdays.map(day => {
              if (calendar.displayDays[day]) {
                return (
                  <div className="day">{day}</div>
                );
              }
            })}
          </div>
          {weeks.map((week: Array<Date>) => {
            return (
              <div className="week">
                {week.map((day: any) => {
                  const isSelected = day.date.toISOString() === selectedDate?.toISOString();
                  const today = new Date();
                  const passedClass = moment(day.date).isBefore(moment(today)) ? "passed" : "";
                  const currentClass = areSameDay(day.date, today) ? "current" : "";
                  const selectedClass = isSelected ? "selected" : "";
                  return (
                    <div 
                      className={`day ${passedClass} ${currentClass} ${selectedClass}`} 
                      onClick={() => setSelectedDate(day.date)}>
                      <div className="marker"><span>{day.date.getDate()}</span></div>
                      <Items items={day.items}/>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>

      </div>

      {selectedDate ? 
        <DayView 
          calendar={calendar} 
          date={selectedDate} 
          handleSetDate={setSelectedDate} 
          handleGetCalendar={handleGetCalendar}
          isInstructor={isInstructor}
          items={getItemsByDate(selectedDate)}
        />
      : null}

    </div>
  );
};

export default CalendarView;
