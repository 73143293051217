
export const colors: any = {
  "red": "#DC3545",
  "green": "#198754",
  "blue": "#4151db",
  "yellow": "#ffc107",
  "orange": "#FF8007",
  "cyan": "#049E8E",
  "pink": "#ff89e3",
  "purple": "#844eca"
};

export const fileExtensions: {[type: string]: string} = {
  "jpg": "file-earmark-image",
  "jpeg": "file-earmark-image",
  "png": "file-earmark-image",
  "gif": "file-earmark-image",
  "svg": "file-earmark-image",
  "pdf": "file-earmark-pdf",
  "doc": "file-earmark-word",
  "docx": "file-earmark-word",
  "txt": "file-earmark-text",
  "html": "file-code",
  "css": "filetype-css",
  "js": "filetype-js",
  "json": "filetype-json",
  "xml": "filetype-xml",
  "csv": "file-earmark-excel",
  "xls": "file-earmark-excel",
  "xlsx": "file-earmark-excel",
  "ppt": "file-earmark-ppt",
  "pptx": "file-earmark-ppt",
  "mp3": "file-earmark-music",
  "wav": "file-earmark-music",
  "mp4": "file-earmark-play",
  "avi": "file-earmark-play",
  "mov": "file-earmark-play",
  "wmv": "file-earmark-play",
  "flv": "file-earmark-play"
};