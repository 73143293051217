import React from "react";
import moment from "moment";
import { useState, useEffect } from "react";
import { get, post, del, put } from 'superagent';
import { Card, Modal } from "react-bootstrap";
import { Calendar } from "../types";
import CalendarView from "../shared/calendar-view";

interface InstructorViewProps {
  calendar: Calendar|null,
  handleGetCalendar: () => void
}

// Empty values for new calendar
const defaultCalendarVals = {
  name: "",
  startDate: "",
  endDate: "",
  displayDays: {
    "sunday": true,
    "monday": true,
    "tuesday": true,
    "wednesday": true,
    "thursday": true,
    "friday": true,
    "saturday": true,
  },
  items: []
};

const defaultDeleteWarningMessage = "Are you sure you want to delete this calendar? This action cannot be undone.";

interface FooterButtonProps {
  calendar: Calendar|null,
  showConfirmDelete: boolean,
  deleteWarningMessage: string,
  handleConfirmDelete: () => void,
  handleDelete: (id: number) => void,
  handleCloseConfirmDelete: () => void,
  handleToggleEditCalendar: (option: boolean) => void
}

function FooterButtons(props: FooterButtonProps) {
  const { 
    calendar, 
    showConfirmDelete,
    deleteWarningMessage,
    handleConfirmDelete, 
    handleCloseConfirmDelete,
    handleDelete,
    handleToggleEditCalendar
  } = props;

  if (showConfirmDelete && calendar && calendar.id) {
    const id = calendar.id;
    return (
      <Modal.Footer>
        <p className="text-danger"><b>{deleteWarningMessage}</b></p>
        <div className="d-flex justify-content-end" style={{width: "100%"}}>
          <button 
            type="button"
            className="btn btn-secondary" 
            onClick={() => handleCloseConfirmDelete()}>
            Cancel
          </button>
          <button 
            type="button"
            className="btn btn-danger" 
            onClick={() => handleDelete(id)}>
            Delete
          </button>
        </div>
      </Modal.Footer>
      )
  }
  return (
    <Modal.Footer>
      {calendar && calendar.id ? 
        <button 
          type="button"
          className="link text-danger" 
          onClick={() => handleConfirmDelete()}>
          Delete
        </button>
        : <div></div> 
      }
      <div>
        <button 
          className="btn btn-secondary" 
          onClick={() => handleToggleEditCalendar(false)}>
          Cancel
          </button>
          <button className="btn btn-primary" type="submit">
            {calendar ? "Save" : "Create"}
          </button>
      </div>
    </Modal.Footer>
  )
}

function InstructorView(props: InstructorViewProps) {
  const { calendar, handleGetCalendar } = props;
  
  const [ calendars, setCalendars ] = useState<Array<Calendar>>([]);
  const [ newCalendar, setNewCalendar ] = useState<Calendar>(defaultCalendarVals);
  const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);
  const [ showConfirmDelete, setShowConfirmDelete ] = useState<boolean>(false);
  const [ showConfirmDeleteModal, setShowConfirmDeleteModal ] = useState<boolean>(false);
  const [ deleteWarningMessage, setDeleteWarningMessage ] = useState<string>("");
  const [ newCalendarError, setNewCalendarError ] = useState<string>("");
  const [ selectedCalendarId, setSelectedCalendarId ] = useState<number|null>(null);

  const getCalendars = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/calendar/all`)
    .withCredentials()
    .then(((res: any) => {
      setCalendars(res.body);
    }), (err: any) => console.error(err.response))
  };

  const setDisplayDays = (day: string, checked: boolean) => {
    let newDisplayDays = {...newCalendar.displayDays};
    newDisplayDays[day] = checked;
    setNewCalendar({...newCalendar, displayDays: newDisplayDays});
  }

  // Takes string mm/dd/yyyy and returns a JavaScript Date
  const getDateFromString = (dateString: string) => {
    let dateParts: Array<string> = dateString.split("-");
    let year = parseInt(dateParts[0]);
    let month = parseInt(dateParts[1]) - 1;
    let day = parseInt(dateParts[2]);
    return new Date(year, month, day);
  }

  // Takes date string (ex: 2024-05-07T21:57:22.000Z) and returns yyyy-mm-dd
  // (this format is required for html date inputs)
  const getReadableDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  const createCalendar = async (e: any) => {
    const startDate = getDateFromString(newCalendar.startDate);
    const endDate = getDateFromString(newCalendar.endDate);

    e.preventDefault();
    await post(`${process.env.REACT_APP_API_URL}/api/calendar`)
    .withCredentials()
    .send({
      ...newCalendar, 
      startDate: startDate.toISOString(), 
      endDate: endDate.toISOString()
    })
    .then(((res: any) => {
      getCalendars();
      handleGetCalendar();
      setNewCalendar(defaultCalendarVals);
      setShowCreateModal(false);
      setNewCalendarError("");
    }), (err: any) => {
      if (err.response.text) {
        setNewCalendarError(err.response.text);
      }
      console.error(err.response.body)
    });
  };

  const deleteCalendar = async () => {
    if (selectedCalendarId) {
      await del(`${process.env.REACT_APP_API_URL}/api/calendar/${selectedCalendarId}`)
      .withCredentials()
      .then(((res: any) => {
        getCalendars();
        setNewCalendar(defaultCalendarVals);
        handleGetCalendar();
        setShowCreateModal(false);
        handleCloseConfirmDelete();
      }), (err: any) => console.error(err.response.body));
      setSelectedCalendarId(null);
      setShowConfirmDeleteModal(false);
    }
  };

  // Set calendar on the course in session
  const selectCalendar = async (id: number) => {
    await put(`${process.env.REACT_APP_API_URL}/api/calendar/${id}/select`)
    .withCredentials()
    .then(((res: any) => {
      handleGetCalendar();
    }), (err: any) => console.error(err.response.body));
  };

  const copyAndUseCalendar = async (id: number) => {
    await post(`${process.env.REACT_APP_API_URL}/api/calendar/${id}/copy`)
    .withCredentials()
    .then(((res: any) => {
      handleGetCalendar();      
    }), (err: any) => console.error(err.response.body));
  }

  // Remove calendar from course in session
  const removeCalendar = async (id: any) => {
    await del(`${process.env.REACT_APP_API_URL}/api/calendar/remove/${id}`)
    .withCredentials()
    .send({courseGuid: null})
    .then(((res: any) => {
      setNewCalendar(defaultCalendarVals);
      handleGetCalendar();
    }), (err: any) => console.error(err.response.body));
  };

  const getResources = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/sakai/resources`)
    .withCredentials()
    .then(((res: any) => {
      console.log(res)
    }), (err: any) => console.error(err.response))
  };

  const handleToggleEditCalendar = (shouldOpen: boolean) => {
    if (calendar && calendar.id) {
      setNewCalendar({
        ...calendar,
        startDate: getReadableDate(calendar.startDate),
        endDate: getReadableDate(calendar.endDate)
      });
      setShowCreateModal(shouldOpen);
    } else {
      setShowCreateModal(shouldOpen);
    }
    if (!shouldOpen && showConfirmDelete) {
      handleCloseConfirmDelete();
    }
    if (!shouldOpen) {
      setNewCalendarError("");
    }
  };

  const handleConfirmDelete = async () => {
    if (calendar) {
      setShowConfirmDelete(true);
      // Check if calendar is used in another course
      await get(`${process.env.REACT_APP_API_URL}/api/calendar/${calendar.id}/check-usage`)
      .withCredentials()
      .then(((res: any) => {
        if (res.body.numContentItems && res.body.numContentItems > 1) {
          setDeleteWarningMessage(`
            This calendar is active in ${res.body.numContentItems - 1} other courses or content items.
            Deleting it will remove it from all locations. This action cannot be undone.
          `)
        }
        else {
          setDeleteWarningMessage(defaultDeleteWarningMessage)
        }
      }), (err: any) => {
        console.error(err.response.body)
      });
    }
  };

  const handleCloseConfirmDelete = () => {
    setShowConfirmDelete(false);
    setDeleteWarningMessage("");
  };

  useEffect(() => {
    getCalendars();
    getResources();
  }, [])

  return (
    <div>

      <div style={{display: calendar ? "none" : "initial"}}>
        <h3>Select a Calendar</h3>

        <br/>

        {calendars.length > 0 ? 
          <button className="btn btn-success" onClick={() => setShowCreateModal(true)}>
            Create new +
          </button>
          : null 
        }

        <div className="calendar-list">
            
          {calendars.map((c: any) => {
            const startDate = moment(c.startDate).format("MM/DD/YYYY");
            const endDate = moment(c.endDate).format("MM/DD/YYYY");

            return (

              <Card key={c.id}>
                <Card.Body>
                  <Card.Title>{c.name}</Card.Title>
                  <Card.Text>
                    <div>{startDate} - {endDate}</div> <br/>
                    <p>
                      <b>Days displayed: </b> 
                      {Object.keys(c.displayDays).map(d => d + ", ")}
                    </p>
                  </Card.Text>
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div>
                      <button 
                        className="btn btn-sm btn-success" 
                        style={{marginRight: "7px"}}
                        onClick={() => selectCalendar(c.id)}>
                          Use
                      </button>
                      <button 
                        className="btn btn-sm btn-success" 
                        onClick={() => copyAndUseCalendar(c.id)}>
                          Use Copy
                      </button>
                    </div>
                    <button className="link text-danger" onClick={() => {
                      setShowConfirmDeleteModal(true)
                      setSelectedCalendarId(c.id)
                    }}>
                      Delete
                    </button>
                    </div>
                </Card.Body>
              </Card>
            )
          })}
        </div>

      </div>

      {calendar ? 
        <div>
          <div className="instructor-nav">
            <span>{calendar.name}</span>
            <i className="bi bi-gear" onClick={() => handleToggleEditCalendar(true)}/>
          </div>
          <CalendarView 
            calendar={calendar} 
            handleGetCalendar={handleGetCalendar} 
            isInstructor={true}/>
        </div>
      : null }

      <div style={{display: calendars.length === 0 ? "initial" : "none"}}>
        <p>You don't have a calendar yet.</p>
        <p>
          <button className="btn btn-success" onClick={() => setShowCreateModal(true)}>
            Create your first calendar +
          </button>
        </p>
      </div>



      <Modal show={showCreateModal} onHide={() => handleToggleEditCalendar(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{calendar ? "Edit Calendar" : "Add Calendar"}</Modal.Title>
        </Modal.Header>

        <form className="form-control" onSubmit={(e) => createCalendar(e)}>

          <Modal.Body>

            Name: <input 
              type="text"
              className="form-control"
              value={newCalendar.name}
              required
              onChange={(e: any) => setNewCalendar({...newCalendar, name: e.target.value})}/>
            <br/>
            Start Date: <input 
              type="date" 
              className="form-control"
              value={newCalendar.startDate}
              required
              onChange={(e: any) => setNewCalendar({...newCalendar, startDate: e.target.value })}/>
            <br/>
            End Date: <input 
              type="date" 
              className="form-control"
              value={newCalendar.endDate}
              required
              onChange={(e: any) => setNewCalendar({...newCalendar, endDate: e.target.value })}/>
            <br/>
            <div className="display-days-container">
              Days to Display: 
              {Object.keys(defaultCalendarVals.displayDays).map(k => {
                return (
                  <div key={k}>
                    {k}: 
                    <input 
                      type="checkbox" 
                      checked={newCalendar.displayDays[k]} 
                      onChange={(e) => setDisplayDays(k, e.target.checked)}/>
                  </div>
                )
              })}
            </div>

            <br/>
            {calendar && calendar.id ? 
              <button className="link" onClick={() => removeCalendar(calendar.id)}>
                Select a different calendar
              </button>
              : null 
            }

            {newCalendarError ? <div className="text-danger"><br/>{newCalendarError}</div> : null}

          </Modal.Body>

          <FooterButtons 
            calendar={calendar} 
            showConfirmDelete={showConfirmDelete}
            deleteWarningMessage={deleteWarningMessage}
            handleConfirmDelete={handleConfirmDelete}
            handleDelete={deleteCalendar}
            handleCloseConfirmDelete={handleCloseConfirmDelete}
            handleToggleEditCalendar={handleToggleEditCalendar}/>

        </form>

      </Modal>


      <Modal show={showConfirmDeleteModal} onHide={() => {
        setShowConfirmDeleteModal(false)
        setSelectedCalendarId(null);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this calendar? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <p className="text-danger"><b>{deleteWarningMessage}</b></p>
          <div className="d-flex justify-content-end" style={{width: "100%"}}>
            <button 
              type="button"
              className="btn btn-secondary" 
              onClick={() => {
                setShowConfirmDeleteModal(false);
                setSelectedCalendarId(null);
              }}>
              Cancel
            </button>
            <button 
              type="button"
              className="btn btn-danger" 
              onClick={() => deleteCalendar()}>
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>


    </div>
  );
};

export default InstructorView;
